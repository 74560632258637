import React from 'react';
import { graphql } from 'gatsby';

import { default as SEO } from '../components/SEO';
import {
  SanityBasicImage,
  SanityNavigationSettings,
  SanityPressItem
} from '../../graphql-types';
import { getHeroContent } from '../redesign/content/press/hero';
import {
  Layout,
  Module,
  Row,
  Column,
  BlockContent,
  PressCard,
  Hero
} from '../redesign';
import { HeroProps } from '../redesign/organisms/hero/hero';

export interface SanityPressPageProps {
  data: {
    node: {
      navigationSettings: SanityNavigationSettings;
      metaTitle: string;
      metaDescription: string;
      metaImage: SanityBasicImage;
      items: SanityPressItem[];
      title: string;
      emailAddress: string;
      description: any;
      cta: any;
    };
  };
}

const PressPage = ({ data: { node } }: SanityPressPageProps) => {
  if (!node) {
    return null;
  }

  return (
    <Layout>
      <SEO
        pageTitle={node.metaTitle}
        pageDescription={node.metaDescription}
        pageImage={node.metaImage}
      />
      <Module>
        <Hero
          {...(getHeroContent(node.emailAddress) as HeroProps)}
          hideHeroImageOnMobile
          textAlign={['center', 'left']}
        />
      </Module>
      <Module marginTop="xbig">
        <Row cols={21} offset={2}>
          <Column>
            <Row cols={[22, 10]} gap={1} offset={1} gridLength={24}>
              {node.items.map(item => {
                return (
                  <Column className="mb-lg">
                    <PressCard
                      image={item.logoImage}
                      heading={item.heading}
                      description={
                        <BlockContent blocks={item._rawDescription} />
                      }
                      cta={item.cta?.[0] as any}
                    />
                  </Column>
                );
              })}
            </Row>
          </Column>
        </Row>
      </Module>
    </Layout>
  );
};

export default PressPage;

export const query = graphql`
  query PressPageQuery {
    node: sanityPressPage {
      metaTitle
      metaDescription
      metaImage {
        hotspot {
          y
          x
          width
          height
        }
        crop {
          bottom
          left
          right
          top
        }
        asset {
          url
        }
      }
      navigationSettings {
        themeNav
        spacer
      }
      emailAddress
      description: _rawDescription
      title
      cta {
        ...SanityButtonsFragment
      }
      items {
        heading
        logoImage {
          _type
          ...SanityImageFragment
        }
        _rawDescription
        cta {
          ...SanityButtonsFragment
        }
      }
    }
  }
`;
