import React from 'react';
import { Link } from '../..';

export const getHeroContent = (email: string) => ({
  eyebrow: 'Learn how Parsley is changing healthcare for the better.',
  headline: 'In the news',
  description: (
    <>
      For press inquiries and interview requests, please email <br />
      <Link
        color="sage"
        className="hero-primary-button"
        variant="p2a"
        destination={`mailto:${email}`}
      >
        {email}
      </Link>
      .
    </>
  ),
  desktopImage: {
    asset: {
      url:
        'https://cdn.sanity.io/images/mur2ep80/production/bb092c939071e2930af0118060f4a7df7e4694d3-1728x2157.png'
    },
    alt: 'doctor_and_patient',
    type: 'rectangleXL'
  }
});
